import React, { Component } from "react";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loading from 'react-fullscreen-loading';
import html2canvas from "html2canvas";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import LocalTaxiOutlinedIcon from '@mui/icons-material/LocalTaxiOutlined';
import { apiConstants } from '../constants';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Link } from "react-router-dom";
import moment from 'moment';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: 400,
    minWidth: 350,
    bgcolor: 'background.paper',
    border: '2px solid rgb(1, 46, 93)',
    borderRadius: '12px',
    boxShadow: 24,
};

class ThankYou extends React.Component {
    constructor(props) {
        super(props);
        // States and props usage
        this.state = {
            loading: true,
            open: false,
            offer: [],
            order_id: "",
            booking: {
                order_id: "",
                location_name: "",
            },
            location_id: 0,
            per_hr:10,
            formData: {
                vehicle_type: "Car",
                vehicle_no: "",
                no_hr: 1,
                payment: "10",
                validity: "",
            }
        };
    }
    componentDidMount() {
        var context = this;
        const queryParameters = new URLSearchParams(window.location.search)
        const order_id = queryParameters.get("order_id");
        
            let formData = new FormData();
        formData.append("razorpay_order_id", order_id);
        axios.post(apiConstants.base_url + "get_payment_details", formData)
        .then(function (response) {
            if (!response.data.error) {
                console.log(response.data.data)
                const formData = { ...context.state.formData };
                formData['vehicle_type'] = response.data.data.vehicle_type;
                formData['vehicle_no'] = response.data.data.vehicle_no;
                var per_hr = response.data.data.payment / response.data.data.no_hr;
                var total_hr = response.data.data.no_hr;
                var total_amount = parseInt(response.data.data.payment);
                var booking = response.data.data;
                for (let index = 0; index < response.data.extend.length; index++) {
                    let element = response.data.extend[index];
                    total_hr += element['no_hr'];
                    total_amount += parseInt(element['payment']);
                }
                booking['no_hr'] = total_hr;
                booking['payment'] = total_amount;
                formData['payment'] = per_hr;
                formData['validity'] = moment(booking.created_at).add(total_hr+1, 'hours').format('DD-MM-YYYY hh:mm A');
                context.setState({ order_id: order_id, booking: booking, loading: false, formData: formData, per_hr: per_hr, location_id: response.data.data.location_id });
                axios.get(apiConstants.base_url+'offer_get?location_id='+response.data.data.location_id)
                .then(function (response) {
                    context.setState({ offer: response.data });
                })
                .catch(function (error) {
                })
                .finally(function () {
                });
            }
        })
        .catch(function (error) {
            console.log(error);
        })
        .finally(function () {
        });
        
        // if (localStorage.getItem("booking_data")) {
        //     var booking_data = JSON.parse(localStorage.getItem("booking_data"));
        //     context.setState({ booking:booking_data });
        // }
    }
    minusNumber = () => {
        const formData = { ...this.state.formData };
        var count = parseInt(this.state.formData.no_hr) - 1;
        count = count < 1 ? 1 : count;
        formData['no_hr'] = count;
        this.setState({ formData },() => {
            this.change_price()
        });
    };
    plusNumber = () => {
        const formData = { ...this.state.formData };
        var count = parseInt(this.state.formData.no_hr) + 1;
        if (count <= 24) {
            formData['no_hr'] = count;
            this.setState({ formData },() => {
                this.change_price()
            });
        }
    };
    change_price = () => {
        const formData = { ...this.state.formData };
        var no_hr = this.state.formData.no_hr;
        var payment = this.state.per_hr*no_hr;
        formData['payment'] = payment;
        var total_hr =this.state.booking.no_hr + no_hr
        formData['validity'] = moment(this.state.booking.created_at).add(total_hr, 'hours').format('DD-MM-YYYY hh:mm A');
        this.setState({ formData });
    };
    download = () => {
        html2canvas(document.body).then(function (canvas) {
            let url = canvas.toDataURL('image/png')
            var a = document.createElement("a"); //Create <a>
            a.href = url; //Image Base64 Goes here
            a.download = "Image.png"; //File name Here
            a.click();
        });
    }

    submitForm = () => {
        const context = this;
        this.setState({ loading: true });
        let formData = new FormData();
        formData.append("payment", this.state.formData.payment);
        formData.append("vehicle_type", this.state.formData.vehicle_type);
        formData.append("vehicle_no", this.state.formData.vehicle_no);
        formData.append("no_hr", this.state.formData.no_hr);
        formData.append("location_id", this.state.location_id);
        formData.append("parent_order_id", this.state.order_id);
        formData.append("created_at", moment(this.state.booking.created_at).add(this.state.booking.no_hr,'hours').format('YYYY-MM-DD HH:mm:ss'));
        formData.append("bay_no", "-");
        formData.append("name", "");
        formData.append("mobile_no", "");
        
        axios.post(apiConstants.base_url+"get_payment_order", formData)
            .then(function (response) {
                console.log(response);
                if (response.data.success) {
                    context.setState({ loading: false });
                    console.log("response:::", response.data.data);
                    response.data.data["callback_url"] = "";
                    response.data.data["handler"] = async (response) => {
                        try {
                            context.setState({ loading: true });
                            const paymentId = response.razorpay_payment_id;
                            console.log(response);
                            let formData = new FormData();
                            var razorpay_order_id = response.razorpay_order_id;
                            formData.append("razorpay_payment_id",response.razorpay_payment_id)
                            formData.append("razorpay_order_id",response.razorpay_order_id)
                            formData.append("razorpay_signature",response.razorpay_signature)
                            axios.post(apiConstants.base_url+"paymentVerify", formData)
                            .then((response1) => {
                                context.setState({ loading: false });
                                if (!response1['data']['error']) {
                                    window.location = "/thankyou?order_id="+context.state.order_id;
                                }
                                else {
                                    toast.error(response1['data']['message']);
                                }
                            })
                            .catch(function (error) {
                                console.log(error);
                            })
                            .finally(function () {
                            });


                        } catch (err) {
                            toast.error("Payment Failed");
                        }
                    };
                    response.data.data["modal"] = {
                        "ondismiss": (context) => {
                            console.log('Checkout form closed');
                        }
                    };
                    const rzp1 = new window.Razorpay(response.data.data);
                    rzp1.open();
                } else {
                    toast.error(response.data.error);
                    context.setState({ loading: false });
                }
            })
            .catch(function (error) {
                console.log(error);
            })
            .finally(function () {
            });
    }
    handleOpen = () => {
        this.setState({open:true})
    }
    handleClose = () => {
        this.setState({open:false})
    }
    handleSharing = async () => {
    if (navigator.share) {
        try {
            const context = this;
            navigator
                .share({
                    title: 'ParkingPal',
                    text: 'Hooray! I parked my vehicle here.Take a look at my booking.',
                    url: "https://onstreet.parkingpal.in/thankyou?order_id="+ context.state.order_id
                    // files: [file],
                })
            .then(() =>
                console.log("Hooray! Your content was shared to tha world")
            );
            // html2canvas(document.body).then(function (canvas) {
            //     const blob = (fetch(canvas.toDataURL('image/png'))).blob();
            //     const file = new File([blob], 'fileName.png', { type: blob.type });
            //     navigator
            //         .share({
            //             title: 'ParkingPal',
            //             text: 'Check out this booking!',
            //             url: "https://onstreet.parkingdeveloper.com/thankyou?order_id="+ context.state.order_id
            //             // files: [file],
            //         })
            //     .then(() =>
            //         console.log("Hooray! Your content was shared to tha world")
            //     );
            // });
        } catch (error) {
            console.log(`Oops! I couldn't share to the world because: ${error}`);
        }
    } else {
      // fallback code
      console.log(
        "Web share is currently not supported on this browser. Please provide a callback"
      );
    }
  };
    render() {
        return (
            <div>
                <main>
                    <div className="p-2 d-flex align_item_center" style={{width: "fit-content",margin: "auto",position: "fixed",top: 0,width: "100%",justifyContent: "center"}}>
                        {/* <img className="d-block" src="https://admin.parkingpal.in/uploads/sites/f17bebcce776096120b5124e3ca09050996deca5.png" alt="" width="160" /> */}
                        <a style={{position: "absolute", right: "12px", display: "flex", flexDirection: "column", top: "4px"}}>
                            <HelpOutlineIcon />
                            info
                        </a>
                    </div>
                </main>
                <div className="container">
                    <main>
                        <div className="p-2 pb-1 text-center">
                            <img className="d-block mx-auto mb-0" src="../assets/img/icons/1.png" style={{ width: "46px"}}/>
                            <h2 style={{flex: 1, color: "#012e5d", fontSize: "16px"}} className="mb-1"><b>Your parking payment is completed</b></h2>
                        </div>
                    </main>
                </div>
                <div className="container">
                    <div style={{borderLeft : "1px solid #012e5d", borderRight : "1px solid #012e5d"}}>
                        <div className="my-1 py-0 p-1" style={{backgroundColor: "#012e5d"}}>
                            <div className="container">
                                <p style={{color: "white",marginBottom: "0px",fontSize: "13px"}}>Booking Summary</p>
                            </div>
                        </div>
                        <main className="container">
                            <div className="row p-1 g-3 align_item_center">
                                <div className="col">
                                    <label className="form-label" style={{ display: "flex", fontSize: "13px", alignItems: "center" }}>
                                        <BadgeOutlinedIcon style={{ width: "16px", marginRight: "4px" }} />
                                        <b style={{fontWeight: "600"}}>Booking ID:</b>
                                        <span style={{marginLeft: "4px"}}>{this.state.booking.order_id}</span>
                                    </label>
                                </div>
                            </div>
                            <div className="row p-1 g-3 align_item_center">
                                <div className="col">
                                    <label className="form-label" style={{ display: "flex", fontSize: "13px", alignItems: "center" }}>
                                        <img src="../assets/img/icons/14.png" style={{ width: "16px", marginRight: "4px" }} />
                                        <b style={{fontWeight: "600"}}>Location:</b>
                                        <span style={{marginLeft: "4px"}}>{this.state.booking.location_name}</span>
                                    </label>
                                </div>
                            </div>
                            <div className="row p-1 g-3 align_item_center">
                                <div className="col">
                                    <label className="form-label" style={{ display: "flex", fontSize: "13px", alignItems: "center" }}>
                                        <LocalTaxiOutlinedIcon style={{ width: "16px", marginRight: "4px" }} />
                                        <b style={{fontWeight: "600"}}>Vehicle:</b>
                                        <span style={{marginLeft: "4px"}}>{this.state.booking.vehicle_type} - <span  style={{textTransform: "uppercase"}}>{this.state.booking.vehicle_no}</span></span>
                                    </label>
                                </div>
                            </div>
                            <div className="row p-1 g-3 align_item_center">
                                <div className="col">
                                    <label className="form-label" style={{ display: "flex", fontSize: "13px", alignItems: "center" }}>
                                        <img src="../assets/img/icons/16.png" style={{ width: "16px", marginRight: "4px" }} />
                                        <b style={{fontWeight: "600"}}>Duration:</b>
                                        <span style={{marginLeft: "4px"}}>{this.state.booking.no_hr} Hr</span>
                                    </label>
                                </div>
                            </div>
                            <div className="row p-1 g-3 align_item_center">
                                <div className="col">
                                    <label className="form-label" style={{ display: "flex", fontSize: "13px", alignItems: "center" }}>
                                        <img src="../assets/img/icons/16.png" style={{ width: "16px", marginRight: "4px" }} />
                                        <b style={{fontWeight: "600"}}>Arrival Time:</b>
                                        <span style={{marginLeft: "4px"}}>{moment(this.state.booking.created_at).format('DD-MM-YYYY hh:mm A')}</span>
                                    </label>
                                </div>
                            </div>
                            <div className="row p-1 g-3 align_item_center">
                                <div className="col">
                                    <label className="form-label" style={{ display: "flex", fontSize: "13px", alignItems: "center" }}>
                                        <img src="../assets/img/icons/17.png" style={{ width: "16px", marginRight: "4px" }} />
                                        <b style={{fontWeight: "600"}}>Exit Before:</b>
                                        <span style={{marginLeft: "4px"}}>{moment(this.state.booking.created_at).add(this.state.booking.no_hr,'hours').format('DD-MM-YYYY hh:mm A')}</span>
                                    </label>
                                </div>
                            </div>
                            <div className="row p-1 g-3 align_item_center">
                                <div className="col">
                                    <label className="form-label" style={{ display: "flex", fontSize: "13px", alignItems: "center" }}>
                                        <img src="../assets/img/icons/18.png" style={{ width: "16px", marginRight: "4px" }} />
                                        <b style={{fontWeight: "600"}}>Amount Paid:</b>
                                        <span style={{marginLeft: "4px"}}>Rs. {this.state.booking.payment}</span>
                                    </label>
                                </div>
                            </div>
                            <div className="p-2 py-0 text-center">
                                <button className="btn btn-dark" onClick={this.download} style={{ borderRadius: "22px", paddingRight: "18px", background: "#32ae54", border: "none", paddingLeft: "6px", fontSize: "14px" }}>
                                    <img src="../assets/img/icons/8.png" style={{marginRight: "3px", width: "25px"}}/>
                                    Download Receipt
                                </button>
                                <button className="btn btn-dark" onClick={this.handleSharing} style={{ borderRadius: "50%", marginLeft: "6px", background: "#32ae54", border: "none", height: "39px", width: "39px", padding: "0" }}>
                                    <img src="../assets/img/icons/10.png" style={{width: "32px"}}/>
                                </button>
                            </div>
                        </main>
                        <div className="text-center">
                            <a className="btn btn-dark container" onClick={this.handleOpen} style={{ marginTop:"10px", borderRadius: "0px", paddingRight: "6px", background: "#32ae54", border: "none", width: "100%", textAlign: "left", paddingLeft: "35px" }}>
                                Need more time? <img src="../assets/img/icons/13.png" style={{marginRight: "6px", width: "32px"}}/>
                                EXTEND
                            </a>
                        </div>
                    </div>
                </div>

                <div className="container"  style={{marginBottom: "98px"}}>
                    <main>
                        {this.state.offer.length <= 0 ? (
                            <div>
                            </div>
                        ) :(
                            <div className="my-2 p-2">
                                <h2 style={{flex: 1, fontSize: "14px"}} className="mb-2"><b>Offers from our partners</b></h2>
                                <div className="text-center" style={{display: "flex",overflow: "scroll",columnGap: "10px"}}>
                                    {
                                        this.state.offer.map(offer => (
                                            <Link to={offer.offer_link} target="_blank">
                                                <img style={{ borderRadius: "10px", maxWidth: "200px"}}
                                                    srcSet={offer.offer_image}
                                                    src={offer.offer_image}
                                                    alt="image"
                                                    className="homes-img"
                                                />
                                            </Link>
                                        ))
                                    }
                                </div>
                            </div>
                        )}
                    </main>
                </div>
                <div className="container" style={{position: "fixed", bottom: "54px", background: "white", paddingBottom: "6px"}}>
                {/* <div className="container" style={{marginBottom: "72px"}}> */}
                    <main>
                        <div className="p-2 py-1 d-flex align_item_center">
                            <div className="download-icon-wrap" style={{ display: "flex", alignItems: "center" }}>
                                <p style={{ marginBottom: "0px", width: "126px", fontSize: "12px" }}>Want to book parking in advance?</p>
                                <div style={{display: "flex", flex: "1"}}>
                                    <a style={{flex : 1}} href="https://play.google.com/store/apps/details?id=com.parkingpal.user" target="_blank" className="download-icon">
                                        <img src="https://parkingpal.in/assets/img/parking/googleplay.svg" style={{width:"100%"}}/>
                                    </a>
                                    <a style={{flex : 1}} href="https://apps.apple.com/us/app/co-parkingpal/id1580013580" target="_blank" className="download-icon">
                                        <img src="https://parkingpal.in/assets/img/parking/appstore.svg" style={{width:"100%"}}/>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </main>
                </div>

                <main>
                    <div className="p-2 d-flex align_item_center" style={{ width: "fit-content", margin: "auto", position: "fixed", bottom: 0, width: "100%", justifyContent: "center", background: "white", boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px", borderTop: "1px solid #0000002b" }}>
                        <div style={{display: "flex", width: "100%"}}>
                            <a href="tel:8888006666" style={{display: "flex", flex: 1, textDecoration: "none",alignItems: "center", color: "black",flexDirection: "column", fontSize:"12px"}}>
                                <img src="../assets/img/icons/2.png" style={{ width:"20px"}} />
                                Call Helpline
                            </a>
                            <a href={'/?location_id='+this.state.location_id} style={{display: "flex", flex: 1, textDecoration: "none",alignItems: "center", color: "#000",flexDirection: "column", fontSize:"12px"}}>
                                <img src="../assets/img/icons/4.png" style={{ width:"20px"}} />
                                Home Page
                            </a>
                        </div>
                    </div>
                </main>
                <Modal
                    open={this.state.open}
                    onClose={this.handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    style={{zIndex:1}}
                >
                    <Box sx={style}>
                        <div style={{ padding: "10px", borderBottom: "1px solid rgb(1, 46, 93)" }}>
                            <div className="d-flex">
                                <h5 style={{flex:1,marginBottom: "0px", color: "rgb(1, 46, 93)", textAlign: "center"}}>Extend Parking</h5>
                                <button style={{ background: "none", border: "none", width: "fit-content", padding: "0px", color: "red" }} onClick={this.handleClose}>
                                    <img style={{width: "24px"}} src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB7ElEQVR4nO2Z3UrDMBTH81Amft01012oCOKt+BAqKA4miCJ44Y0PIIj4id6IdzrfwyFDbzargjYbrZVFWtexuXY2TZN0kD+cu5X9fzkn56QNAFpaWlqZVh2jWWKge8tAhGBEZYTl/ZcBS3VjaIbLvIXRjizTJAoGw63kK6/YPGkHnGYG8MomQwB37AAYWhkC+EwAoNo06goNQKJWJz9GSW54QDOQH6ffN9fUPT1ihrALK36oA2iZp+a7HywQ9voSpVWT0tortTfW1AC4Z8dt80F8HR78C9E2HzxXiweROkBjYY42H8o9EP0y0WPebAEUV9XsARYIHvNEZBuNA8FrnoieA/0g7MIyt3kiY5BFQdCXN27zRNYkjoTgNE9kHiV8iMpTaCbsYryeT1QC+Bu29qfmgz1xwj6xiUyA0G6TEgQQDRDVKpuV51QggEiAfn0+ssUyQgBRAHGGVBoQQAQAy4TlhQBpAyQ5HjQW52mz/Bh+isWSAdyri24jVTPWC0pYJpz9PQUlNDFC3cvzRBO2E8KJYV7cJp4c9TPB8mrYWU7O7nbs34sBkBhAA+CBywDMzqdFA36wAxiwpNw4DgLeMgN4lwvqjaPfyKEpkETe5YJq85aBNgGPvMsF7/u83D0BLa9sEq+8lpaWFpClH85QF54vuUIVAAAAAElFTkSuQmCC"/>
                                </button>
                            </div>
                        </div>
                        <main className="container" style={{maxWidth: "276px"}}>
                            <div className="row p-1 g-3 align_item_center">
                                <div className="col">
                                    <label className="form-label" style={{ display: "flex", fontSize: "12px", alignItems: "center" }}>
                                        <img src="../assets/img/icons/14.png" style={{ width: "14px", marginRight: "4px" }} />
                                        <b style={{fontWeight: "600"}}>Location:</b>
                                        <span style={{marginLeft: "4px"}}>{this.state.booking.location_name}</span>
                                    </label>
                                </div>
                            </div>
                            <div className="row p-1 g-3 align_item_center">
                                <div className="col">
                                    <label className="form-label" style={{ display: "flex", fontSize: "12px", alignItems: "center" }}>
                                        <LocalTaxiOutlinedIcon style={{ width: "14px", marginRight: "4px" }} />
                                        <b style={{fontWeight: "600"}}>Vehicle:</b>
                                        <span style={{marginLeft: "4px"}}>{this.state.booking.vehicle_type} - <span  style={{textTransform: "uppercase"}}>{this.state.booking.vehicle_no}</span></span>
                                    </label>
                                </div>
                            </div>
                            <div className="row p-1 g-3 align_item_center">
                                <div className="col">
                                    <label for="no_hr" className="form-label" style={{fontSize : "13px", fontWeight: 600}}>Extend parking by (Hrs)</label>
                                </div>
                            </div>
                            <div className="row p-1 g-3 align_item_center">
                                <div className="col">
                                    <div className="input-group" style={{width: "120px",margin: "auto"}}>
                                        <span className="minus number_span input-group-text" onClick={this.minusNumber} >-</span>
                                        <input type="text" className="form-control number_input" id="no_hr" name="no_hr" value={this.state.formData.no_hr} min="1" max="24" style={{ pointerEvents: "none"}} />
                                        <span className="plus number_span input-group-text" onClick={this.plusNumber}>+</span>
                                    </div>
                                </div>
                            </div>
                            <div className="row p-1 g-3 align_item_center">
                                <div className="col text-center">
                                    <label for="no_hr" className="form-label mb-0" style={{ fontSize: "12px" }}>Valid Till</label>
                                    <span id="till" style={{ fontSize: "12px", marginBottom: "2px" }}>{this.state.formData.validity}</span>
                                </div>
                            </div>
                            <div className="row p-1 g-3 align_item_center">
                                <div className="col-5">
                                    <label for="location" className="form-label" style={{fontSize : "13px", fontWeight: 600}}>Parking Fee</label>
                                </div>
                                <div className="col-7">
                                    <p style={{marginBottom: "0px", fontSize : "13px", fontWeight: 600}}>Rs. <span id="payment_amount">{this.state.formData.payment} </span></p>
                                </div>
                            </div>
                            <div className="row pb-0 align_item_center" style={{justifyContent: "center"}}>
                                <button className="btn btn-primary btn-lg" style={{ width: "fit-content", borderRadius: "24px", padding: "12px 26px", color: "white", background: "#4caf50", border: "white", fontWeight: 700, fontSize: "13px" }}
                                    onClick={ this.submitForm}>PAY NOW</button>
                            </div>
                        </main>
                        <div className="pt-0 pb-2 text-center" style={{}}>
                            <p className="lead" style={{fontSize: "12px",color: "red",marginTop: "4px",marginBottom: "0px"}}>
                                If an officer checks your number plate, they will be notified that your parking has been extended
                            </p>
                        </div>
                    </Box>
                </Modal>
                 { this.state.loading?<Loading loading background="#ffffff80" loaderColor="rgb(76 175 80)" />:null}
            </div>
        );
    }
}

export default ThankYou;