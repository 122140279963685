import React, { Component } from "react";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loading from 'react-fullscreen-loading';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { apiConstants } from '../constants';
import moment from 'moment';

class Home extends React.Component{
    constructor(props) {
        super(props);
        // States and props usage
        this.state = {
            loading: true,
            location_details: null,
            location_prefix: "",
            location_name: "",
            location_logo: "",
            location_id:0,
            formData: {
                vehicle_type: "Car",
                vehicle_no: "",
                no_hr: 1,
                payment: "10",
                validity: moment().add(1, 'hours').format('DD-MM-YYYY hh:mm A'),
            },
        };
    }
    componentDidMount() {
        var context = this;
        const queryParameters = new URLSearchParams(window.location.search)
        const location_id = queryParameters.get("location_id")
        let location_details = [];
        axios.get(apiConstants.base_url+'location_get')
        .then(function (response) {
            for (let index = 0; index < response.data.length; index++) {
                let element = response.data[index];
                if (parseInt(location_id)===parseInt(element['id'])) {
                    location_details = element;
                    console.log(location_details)
                    let location_name_array = location_details["location_name"].split(" ");
                    let location_prefix = location_name_array[0];
                    location_name_array.splice(0, 1);
                    let location_name = location_name_array.join(" ");
                    var dynamic_price = [];
                    if (location_id == 34) {
                        dynamic_price = {
                            Bike: [
                                {
                                    start_hr: 0,
                                    max_hr: 2,
                                    price: 15,
                                    vehicle_type: "Bike"
                                },
                                {
                                    start_hr: 2,
                                    max_hr: 6,
                                    price: 30,
                                    vehicle_type: "Bike"
                                },
                                {
                                    start_hr: 6,
                                    max_hr: -1,
                                    price: 60,
                                    vehicle_type: "Bike"
                                }
                            ],
                            Car: [
                                {
                                    start_hr: 0,
                                    max_hr: 2,
                                    price: 35,
                                    vehicle_type: "Car"
                                },
                                {
                                    start_hr: 2,
                                    max_hr: 6,
                                    price: 60,
                                    vehicle_type: "Car"
                                },
                                {
                                    start_hr: 6,
                                    max_hr: -1,
                                    price: 80,
                                    vehicle_type: "Car"
                                }
                            ]
                        };
                    }
                    context.setState({ location_details:location_details,location_name:location_name,location_prefix:location_prefix,location_id:location_id,loading:false,location_logo:location_details.location_logo,dynamic_price:dynamic_price },() => {
                        context.change_price()
                    });
                }
            }
            if (location_details.length === 0) {
                location_details = response.data[0];
                console.log(location_details)
                let location_name_array = location_details["location_name"].split(" ");
                let location_prefix = location_name_array[0];
                location_name_array.splice(0, 1);
                let location_name = location_name_array.join(" ");
                context.setState({ location_details:location_details,location_name:location_name,location_prefix:location_prefix,location_id:location_details['id'],loading:false,location_logo:location_details.location_logo,dynamic_price:[] },() => {
                    context.change_price()
                });
            }
        })
        .catch(function (error) {
            console.log(error);
        })
        .finally(function () {
        });
    }

    handleChange = ({ currentTarget: input }) => {
        const formData = { ...this.state.formData };
        formData[input.name] = input.value;
        this.setState({ formData },() => {
            this.change_price()
        });
    };
    minusNumber = () => {
        const formData = { ...this.state.formData };
        var count = parseInt(this.state.formData.no_hr) - 1;
        count = count < 1 ? 1 : count;
        formData['no_hr'] = count;
        this.setState({ formData },() => {
            this.change_price()
        });
    };
    plusNumber = () => {
        const formData = { ...this.state.formData };
        var count = parseInt(this.state.formData.no_hr) + 1;
        if (count <= 24) {
            formData['no_hr'] = count;
            this.setState({ formData },() => {
                this.change_price()
            });
        }
    };
    change_price = () => {
        const formData = { ...this.state.formData };
        var no_hr = this.state.formData.no_hr;
        var vehicle_type = this.state.formData.vehicle_type;
        var location_details = this.state.location_details;
        var dynamic_price = this.state.dynamic_price;
        var payment = 0;
        if(vehicle_type==="Car"){
            payment = location_details['car_charges']*no_hr;
        }else if(vehicle_type==="Bike"){
            payment = location_details['bike_charges']*no_hr;
        }else if(vehicle_type==="Rikshaw"){
            payment = location_details['rikshaw_charges']*no_hr;
        }else if(vehicle_type==="Mini Tempo"){
            payment = location_details['mini_tempo_charges']*no_hr;
        }else if(vehicle_type==="Mini Bus"){
            payment = location_details['min_bus_charges']*no_hr;
        }else if(vehicle_type==="Tempo/Truck/Bus"){
            payment = location_details['bus_charges']*no_hr;
        }
        if (dynamic_price[vehicle_type]) {
            var max_cost = 0;
            var costing = 0;
            for (var cost of dynamic_price[vehicle_type]) {
                if (no_hr <= cost['max_hr'] && costing == 0) {
                    costing=cost['price'];
                }
                if (cost['max_hr']==-1) {
                    max_cost = cost['price'];
                }
            }
            if (costing == 0 && max_cost!=0) {
                payment = max_cost;
            }
            if (costing != 0) {
                payment = costing;
            }
        }
        formData['payment'] = payment;
        formData['validity'] = moment().add(no_hr, 'hours').format('DD-MM-YYYY hh:mm A');
        this.setState({ formData });
    };
    submitForm = () => {
        let pattern = /[A-Za-z]{2}[0-9]{2}[A-Za-z]{1,2}[0-9]{4}/;
        if (this.state.formData.vehicle_no === "") {
            toast.error("Please Enter Vehicle Number!")
        } else if (!pattern.test(this.state.formData.vehicle_no)) {
            toast.error("Please Enter Correct Vehicle Number!(AA01AB1234)")
        } else {
            const context = this;
            this.setState({ loading: true });
            let formData = new FormData();
            formData.append("payment", this.state.formData.payment);
            formData.append("vehicle_type", this.state.formData.vehicle_type);
            formData.append("vehicle_no", this.state.formData.vehicle_no);
            formData.append("no_hr", this.state.formData.no_hr);
            formData.append("location_id", this.state.location_id);
            formData.append("bay_no", "-");
            formData.append("name", "");
            formData.append("mobile_no", "");
            
            axios.post(apiConstants.base_url+"get_payment_order", formData)
                .then(function (response) {
                    console.log(response);
                    if (response.data.success) {
                        context.setState({ loading: false });
                        console.log("response:::", response.data.data);
                        response.data.data["callback_url"] = "";
                        response.data.data["handler"] = async (response) => {
                            try {
                                context.setState({ loading: true });
                                const paymentId = response.razorpay_payment_id;
                                console.log(response);
                                let formData = new FormData();
                                var razorpay_order_id = response.razorpay_order_id;
                                formData.append("razorpay_payment_id",response.razorpay_payment_id)
                                formData.append("razorpay_order_id",response.razorpay_order_id)
                                formData.append("razorpay_signature",response.razorpay_signature)
                                axios.post(apiConstants.base_url+"paymentVerify", formData)
                                .then((response1) => {
                                    context.setState({ loading: false });
                                    if (!response1['data']['error']) {
                                        localStorage.setItem("booking_data", JSON.stringify(response1['data']['data']));
                                        window.location = "/thankyou?order_id="+razorpay_order_id;
                                    }
                                    else {
                                        toast.error(response1['data']['message']);
                                    }
                                })
                                .catch(function (error) {
                                    console.log(error);
                                })
                                .finally(function () {
                                });


                            } catch (err) {
                                toast.error("Payment Failed");
                            }
                        };
                        response.data.data["modal"] = {
                            "ondismiss": (context) => {
                                console.log('Checkout form closed');
                            }
                        };
                        const rzp1 = new window.Razorpay(response.data.data);
                        rzp1.open();
                    } else {
                        toast.error(response.data.error);
                        context.setState({ loading: false });
                    }
                })
                .catch(function (error) {
                    console.log(error);
                })
                .finally(function () {
                });
        }
    }
    render() {
        return ( 
            <div>
                <main>
                    <div className="p-2 d-flex align_item_center" style={{width: "fit-content",margin: "auto",position: "fixed",top: 0,width: "100%",justifyContent: "center",background: "white",boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px"}}>
                        <img className="d-block" src="../assets/img/Logo.png" alt="" width="160" />
                        <a style={{position: "absolute", right: "12px", display: "flex", flexDirection: "column"}}>
                            <HelpOutlineIcon />
                            info
                        </a>
                    </div>
                </main>
                <div className="container" style={{marginTop: "56px"}}>
                    <main>
                        <div className="p-2 d-flex align_item_center">
                            <img className="d-block mx-auto" srcSet={this.state.location_logo} src={this.state.location_logo} alt="" width="82"></img>
                        </div>
                        <div className="pb-2 d-flex align_item_center text-center">
                            <h2 style= {{ flex: 1, fontSize: "20px" }} className="mb-1"><b>Your are paying for</b></h2>
                        </div>
                        <div className="m-2 mt-0 d-flex align_item_center text-center" style={{ background:"#4caf50",color: "white" }}>
                            <h5 style={{ marginBottom: "0", marginLeft: "0.25rem" }}><b>LOCATION</b></h5>
                            <h1 style={{ flex: 1,marginBottom: "0"}}><b>{ this.state.location_prefix }</b></h1>
                            <p style={{ marginBottom: "0", marginRight: "0.375rem", whiteSpace: "normal", flex: 1 }}><b>{this.state.location_name}</b></p>
                        </div>

                        <div className="row p-1 g-3 align_item_center">
                            <div className="col-5">
                                <label for="vehicle_type" className="form-label">Type of Vehicle</label>
                            </div>
                            
                            <div className="col-7">
                                <select
                                    className="form-select"
                                    id="vehicle_type"
                                    name="vehicle_type"
                                    onChange={this.handleChange}
                                    value={this.state.formData.vehicle_type}
                                >
                                    <option value="Car">Car</option>
                                    <option value="Bike">Bike</option>
                                    <option value="Rikshaw">Rikshaw</option>
                                    <option value="Mini Tempo">Mini Tempo</option>
                                    <option value="Mini Bus">Mini Bus</option>
                                    <option value="Tempo/Truck/Bus">Tempo/Truck/Bus</option>
                                </select>
                            </div>
                        </div>

                        <div className="row p-1 g-3 align_item_center">
                            <div className="col-5">
                                <label for="vehicle_no" className="form-label">Vehicle No</label>
                            </div>
                            
                            <div className="col-7">
                                <input type="text" style={{ textTransform: "uppercase" }} className="form-control" maxlength="10" id="vehicle_no" name="vehicle_no" placeholder="" value={this.state.formData.vehicle_no} onChange={this.handleChange} />
                                <span style={{ fontSize: "10px", marginBottom: "2px" }}>For e.g. MH12JE1293</span>
                            </div>
                        </div>

                        <div className="row p-1 g-3 align_item_center">
                            <div className="col-5">
                                <label for="no_hr" className="form-label">Enter No of hrs</label>
                            </div>
                            
                            <div className="col-7">
                                <div className="input-group">
                                <span className="minus number_span input-group-text" onClick={this.minusNumber} >-</span>
                                <input type="text" className="form-control number_input" id="no_hr" name="no_hr" value={this.state.formData.no_hr} min="1" max="24" style={{ pointerEvents: "none"}} />
                                <span className="plus number_span input-group-text" onClick={this.plusNumber}>+</span>
                                </div>
                            </div>
                        </div>

                        <div className="row p-1 g-3 align_item_center">
                            <div className="col-5">
                            </div>
                            <div className="col-7 text-center">
                                <label for="no_hr" className="form-label mb-0" style={{ fontSize: "14px" }}>Valid Till</label>
                                <span id="till" style={{ fontSize: "12px", marginBottom: "2px" }}> {this.state.formData.validity}</span>
                            </div>
                        </div>
                    </main>
                </div>
                <div className="my-1 py-0 p-1" style={{backgroundColor: '#012e5d'}}>
                    <div className="container">
                        <p style={{color: "white",marginBottom: "0px"}}>Payment Summary </p>
                    </div>
                </div>
                <div className="container">
                    <main>
                        <div className="row p-1 g-3 align_item_center">
                        <div className="col-5">
                            <label for="location" className="form-label">Parking Fee</label>
                        </div>
                        <div className="col-7">
                            <p style={{marginBottom: "0px"}}>Rs. <span id="payment_amount">{this.state.formData.payment} </span></p>
                            <input type="hidden" id="payment" name="payment" value={this.state.formData.payment}/>
                        </div>
                        </div>
                        <div className="row g-3 py-2 pb-0 align_item_center" style={{justifyContent: "center"}}>
                            <button className="btn btn-primary btn-lg" style={{ width: "fit-content", borderRadius: "24px", padding: "10px 46px", color: "white", background: "#4caf50", border: "white", fontWeight: 700, fontSize: "20px" }}
                                onClick={ this.submitForm}>PAY NOW</button>
                        </div>
                    </main>
                </div>
                <div className="container" style={{marginBottom: "72px"}}>
                    <main>
                        <div className="p-2 pt-0 pb-0 text-center" style={{}}>
                            <p className="lead" style={{ fontSize: "12px", marginTop: "4px", marginBottom: "0px" }}>
                                If an officer checks your number plate, they will be notified that your parking is validated
                            </p>
                            <p className="lead" style={{fontSize: "12px",color: "red",marginTop: "4px",marginBottom: "0px"}}>
                                Park at your own risk. Company /corporation not responsible for any loss or damage
                            </p>
                        </div>
                    </main>
                </div>
                <main>
                    <div className="p-2 d-flex align_item_center" style={{ width: "fit-content", margin: "auto", position: "fixed", bottom: 0, width: "100%", justifyContent: "center", background: "white", boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px", borderTop: "1px solid #0000002b" }}>
                        <div style={{display: "flex", width: "100%"}}>
                            <a href="tel:8888006666" style={{display: "flex", flex: 1, textDecoration: "none",alignItems: "center", color: "black",flexDirection: "column", fontSize:"12px"}}>
                                <img src="../assets/img/icons/2.png" style={{ width:"20px"}} />
                                Call Helpline
                            </a>
                            <a href="#" style={{display: "flex", flex: 1, textDecoration: "none",alignItems: "center", color: "#4caf50",flexDirection: "column", fontSize:"12px"}}>
                                <img src="../assets/img/icons/5.png" style={{ width:"20px"}} />
                                Home Page
                            </a>
                        </div>
                    </div>
                </main>
                <ToastContainer
                    position="bottom-center"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss={false}
                    draggable
                    pauseOnHover={false}
                    theme="light"
                />
                { this.state.loading?<Loading loading background="#ffffff80" loaderColor="rgb(76 175 80)" />:null}
            </div>
        );
    }
}
export default Home;